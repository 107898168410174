import { FaChevronRight } from "react-icons/fa6";
import style from "./Route.module.css";

const Routes = ({ open, setOpen }) => {
  return (
    <div className={`${style.popup_overlay} ${style.sidebar_overlay} ${style.sidebar_open}`}>
      <div className={style.sidebar}>
        <div className={style.sidebar_header}>
          <div className={style.action}>      
            <button
              className={`${style.action_button} ${style.close}`}
              onClick={() => setOpen(!open)}
            >
              <FaChevronRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export {  Routes };