import { useGoogleMap } from "../../lib/hooks/useGoogleMap";

const Map = () => {
  const mapRef = useGoogleMap();
  return (
    <div
      style={{ height: "100%", width: "100%", position: "relative" }} ref={mapRef}
    />
  );
}

export { Map };
