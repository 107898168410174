const lagos = {
  lat: 6.522968010844044,
  lng: 3.3880751738117634,
};

export const mapOptions = {
  center: {
    lat: lagos.lat,
    lng: lagos.lng,
  },
  zoom: 12,
  mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
};

export const loaderConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  libraries: ["places", "geometry", "marker"],
  version: "weekly",
  region: "NG",
};
