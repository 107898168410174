import { useSearchModalContext } from "../../lib/contexts/SearchModalContext";
import { Suggestions } from "./Suggestion";

import { SuggestionSkeleton } from "./SuggestionSkeleton";
import style from "./AutoComplete.module.css";

const SuggestionState = ({ status }) => {
  switch (status) {
    case "pending":
      return <SuggestionSkeleton />;
    case "success":
      return <Suggestions />;
    case "failed":
      return (
        <div className={style.autocomplete_error}>
          <span>Error fetching places</span>
        </div>
      );
    default:
      break;
  }
};

const AutoComplete = () => {
  const { value, status, inputSelected, handleChange } =
    useSearchModalContext();
  return (
    <div className={style.autocomplete}>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={inputSelected}
        autoFocus
      />
      <SuggestionState status={status} />
    </div>
  );
};

export { AutoComplete };
