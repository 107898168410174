import style from "./Sidebar.module.css";

const Sidebar = ({ setOpen, open }) => {
  return (
    <div className={style.sidebar_container}>
      <button className={style.hamburger} type="button" onClick={() => setOpen(!open)}>
        <div className={style.line}></div>
        <div className={style.line}></div>
        <div className={style.line}></div>
      </button>
    </div>
  )
};

export { Sidebar };
