import { IoClose } from "react-icons/io5";
import { useSearchModalContext } from "../../lib/contexts/SearchModalContext";
import { Modal } from "../Modal/Modal";
import { AutoComplete } from "./AutoComplete";

import style from "./SearchModal.module.css";

const SearchModal = () => {
  const { isOpen, onClose } = useSearchModalContext();

  return (
    <Modal isOpen={isOpen}>
      <Modal.Header>
        <Modal.CancelAction>
          <button
            className={`${style.button} ${style.button_close}`}
            onClick={onClose}
          >
            <IoClose />
          </button>
        </Modal.CancelAction>
      </Modal.Header>
      <Modal.Body>
        <AutoComplete />
      </Modal.Body>
    </Modal>
  );
};

export { SearchModal };
