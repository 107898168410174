import { useState } from "react";
import { MapProvider } from "../lib/contexts/MapContext";
import { SearchModalProvider } from "../lib/contexts/SearchModalContext";
import { SearchArea } from "../components/SearchArea/SearchArea";
import { Sidebar } from "../components/SideBar/Sidebar";
import { Map } from "../components/Map/Map";
import { Routes } from "../components/Routes/Routes";

const Main = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <MapProvider>
        <SearchModalProvider>
          <SearchArea open={open} setOpen={setOpen} />
        </SearchModalProvider>
        <Map />
        <Sidebar open={open} setOpen={setOpen} />
        {open && <Routes open={open} setOpen={setOpen} />}
      </MapProvider>
    </>
  );
};

export { Main };
