import { useEffect, useRef, useMemo } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { loaderConfig, mapOptions } from "../../utils/map.options";
import { InfoWindowContent } from "../../utils/infoWindow";
import { useMapContext } from "../contexts/MapContext";
import { useLocation } from "./useLocation";

const mapInterfaceOptions = {
  mapTypeControl: false,
  zoomControl: false,
  fullscreenControl: false,
};

const useGoogleMap = () => {
  const { coords, selectedRoute } = useMapContext();
  const [location] = useLocation();
  const mapRef = useRef(null);
  const memoizedMapOptions = useMemo(() => mapOptions, []);

  useEffect(() => {
    const loader = new Loader(loaderConfig);
    loader
      .importLibrary("maps")
      .then(async ({ Map, TrafficLayer, InfoWindow, Polyline }) => {
        const { AdvancedMarkerElement, PinElement } =
          await loader.importLibrary("marker");
        const { encoding } = await loader.importLibrary("geometry");
        const { LatLngBounds } = await loader.importLibrary("core");
        const map = new Map(mapRef.current, memoizedMapOptions);
        map.setOptions(mapInterfaceOptions);
        const trafficLayer = new TrafficLayer();
        trafficLayer.setMap(map);
        if (location !== null) {
          new AdvancedMarkerElement({
            map: map,
            position: {
              lat: location?.lat,
              lng: location?.lng,
            },
            title: "U",
          });
        }

        if (Object.keys(coords).length > 0) {
          const infoWindow = new InfoWindow();
          const bounds = new LatLngBounds();
          Object.values(coords).forEach((coord) => {
            const pinedScale = new PinElement({
              scale: 0.8,
            });
            const marker = new AdvancedMarkerElement({
              map: map,
              position: coord,
              title: coord.address,
              content: pinedScale.element,
            });
            marker.addListener("click", ({ domEvent, latLng }) => {
              infoWindow.close();
              infoWindow.setContent(InfoWindowContent(coord));
              infoWindow.open(marker.map, marker);
            });
            bounds.extend({
              lat: coord.lat,
              lng: coord.lng,
            });
            map.fitBounds(bounds);
          });
        }

        if (selectedRoute !== null) {
          const routePath = new Polyline({
            path: encoding.decodePath(selectedRoute?.polyline?.encodedPolyline),
            geodesic: true,
            strokeColor: "#842feb",
            strokeOpacity: 1.0,
            strokeWeight: 5,
          });
          routePath.setMap(map);
        } else {
          // console.log(null);
        }
      })
      .catch((e) => {
        // do something
      });
  }, [memoizedMapOptions, coords, selectedRoute, location]);
  return mapRef;
};

export { useGoogleMap };
