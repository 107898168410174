import { mapOptions } from "../utils/map.options";

const FIELDMASK = "places.displayName,places.formattedAddress,places.location,places.id";
const URL = "https://places.googleapis.com/v1/places:searchText";

const getPlace = async ({ textQuery, signal }) => {
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_MAP_API_KEY,
      "X-Goog-FieldMask": FIELDMASK
    },
    body: JSON.stringify({
      textQuery,
      locationBias: {
        circle: {
          center: {
            latitude: mapOptions.center.lat,
            longitude: mapOptions.center.lng,
          },
        }
      }
    }),
    signal
  });
  return await response.json();
};

export { getPlace };
