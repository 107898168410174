import { convertTime } from "./convertTime";

const parseCoord = (coord) => {
  return {
    distance: Number.parseFloat(coord.distanceMeters / 1000).toFixed(2),
    duration: convertTime(parseInt(coord.duration)),
    description: coord.description,
    polyline: {
      encodedPolyline: coord?.polyline?.encodedPolyline,
    },
  };
};

const parseCoords = (coords) => {
  return coords.map((coord) => parseCoord(coord));
};

export { parseCoords };
