import style from "./SuggestionSkeleton.module.css"

const SuggestionSkeleton = () => {
  return (
    <div className={style.skeleton_container}>
      {Array.of(1,2,3,4,5,6).map((_, index) => <div key={index} className={style.skeleton_item}></div>)}
    </div>
  );
};

export { SuggestionSkeleton };
