import { Children, cloneElement } from "react";
import { createPortal } from "react-dom";

import style from "./Modal.module.css";

const Modal = ({ children, isOpen = false }) => {
  return createPortal(
    <div className={`${style.modal} ${isOpen && style.show}`}>
      <div className={style.modal_background}>
        <div className={`${style.modal_content} ${isOpen && style.fade_in}`}>
          {Children.map(children, (child, index) => {
            return cloneElement(child);
          })}
        </div>
      </div>
    </div>,
    document.body,
  );
};

const CancelAction = ({ children }) => children;

const Header = ({ children }) => (
  <div className={style.modal_header}>{children}</div>
);
const Body = ({ children }) => (
  <div className={style.modal_body}>{children}</div>
);

Modal.CancelAction = CancelAction;
Modal.Header = Header;
Modal.Body = Body;

export { Modal };
