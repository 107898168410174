import style from "./SearchButton.module.css";

const SearchButton = ({ placeholder, icon, onClick }) => {
  return (
    <button className={`${style.button}`} onClick={onClick}>
      <div className={style.search_button_content}>
        <span className={style.icon}>{icon}</span>
        <span className={style.search_button_content_text}>{placeholder}</span>
      </div>
    </button>
  );
};

export { SearchButton };
