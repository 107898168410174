import { useState, useRef } from "react";

import { getPlace } from "../../services/PlaceService";
import { useDebounce } from "./useDebounce";

const useAutoComplete = () => {
  const abortController = useRef();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [status, setStatus] = useState("idle");
  const debouncedRequest = useDebounce(async () => {
    abortController.current?.abort();
    abortController.current = new AbortController();
    try {
      setStatus("pending");
      if (value.length > 1) {
        const filteredSuggestions = await getPlace({
          textQuery: value, signal: abortController.current?.signal
        });
        setSuggestions(filteredSuggestions?.places);
      } else {
        setSuggestions([]);
      }
      setStatus("success");
    } catch (e) {
      console.log(e)
      setStatus("failed");
    }
  });

  const clearData = () => {
    setValue("");
    setSuggestions([]);
  };
  const handleChange = async(e) => {
    const value = e.target.value;
    setValue(value);
    debouncedRequest();
  };
  return { value, suggestions, status, handleChange, clearData };
};

export { useAutoComplete };
