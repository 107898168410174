import { useContext, createContext, } from "react";
import { useRouteCompute } from "../hooks/useRouteCompute";

const SearchModalContext = createContext();

export const useSearchModalContext = () => useContext(SearchModalContext);

const SearchModalProvider = ({ children }) => {
  const {
    isOpen,
    inputSelected,
    origin,
    destination,
    value,
    suggestions,
    status,
    handleChange,
    onOpen,
    onClose,
    onPlaceSelected
  } = useRouteCompute();
  return (
    <SearchModalContext.Provider
      value={{
        isOpen,
        inputSelected,
        origin,
        destination,
        value,
        suggestions,
        status,
        handleChange,
        onOpen,
        onClose,
        onPlaceSelected
      }}
    >
      {children}
    </SearchModalContext.Provider>
  );
};

export { SearchModalProvider };
