import { useSearchModalContext } from "../../lib/contexts/SearchModalContext";
import style from "./Suggestion.module.css";

const Suggestions = () => {
  const { onPlaceSelected, suggestions } = useSearchModalContext();
  return (
    <ul className={style.suggestion}>
      {suggestions?.map((suggestion, index) => (
        <li key={index} className={style.search_item}>
          <button
            className={style.search_item_button}
            onClick={() =>
              onPlaceSelected({
                location: suggestion?.location,
                address: suggestion?.formattedAddress,
              })
            }
          >
            <span className={style.search_button_text}>
              {suggestion?.formattedAddress}
            </span>
          </button>
        </li>
      ))}
    </ul>
  );
};

export { Suggestions };
