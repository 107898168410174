import { useEffect, useState } from "react";
import { computeRoute } from "../../services/RouteService";
import { parseCoords } from "../../utils/parseCoords";
import { useMapContext } from "../contexts/MapContext";
import { useAutoComplete } from "./useAutoComplete";

const useRouteCompute = () => {
  const { value, suggestions, status, handleChange, clearData } =
    useAutoComplete();
  const { setCoords, setAltCoords, setSelectedRoute } = useMapContext();
  const [isOpen, setIsOpen] = useState(false);
  const [inputSelected, setInputSelected] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const onOpen = (input) => {
    setIsOpen(true);
    setInputSelected(input);
  };
  const onClose = () => {
    setIsOpen(false);
    setInputSelected(null);
    clearData();
  };

  const onPlaceSelected = async (value) => {
    if (inputSelected === "origin") {
      setOrigin(value);
    }

    if (inputSelected === "destination") {
      setDestination(value);
    }

    onClose();
  };
  useEffect(() => {
    if (origin !== null && destination !== null) {
      computeRoute(origin.location, destination.location)
        .then((res) => {
          setCoords({
            origin: {
              lat: origin.location.latitude,
              lng: origin.location.longitude,
              address: origin.address,
            },
            destination: {
              lat: destination.location.latitude,
              lng: destination.location.longitude,
              address: destination.address,
            },
          });
          setSelectedRoute(res?.routes[0]);
          setAltCoords(parseCoords(res?.routes));
        })
        .catch((e) => console.log(e));
    }
  }, [origin, destination, setAltCoords, setCoords, setSelectedRoute]);
  return {
    isOpen,
    inputSelected,
    origin,
    destination,
    value,
    suggestions,
    status,
    handleChange,
    onOpen,
    onClose,
    onPlaceSelected,
  };
};

export { useRouteCompute };
