import { MdOutlineTripOrigin } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";

import { useSearchModalContext } from "../../lib/contexts/SearchModalContext";
import { useMapContext } from "../../lib/contexts/MapContext";
import { SearchButton } from "../SearchButton/SearchButton";
import { SearchModal } from "../SearchModal/SearchModal";

import { RouteList } from "../RouteList/RouteList";
import { RouteMobile } from "../RouteMobile/RouteMobile";
import style from "./SearchArea.module.css";

const SearchArea = ({ open, setOpen }) => {
  const { onOpen, origin, destination, isOpen } = useSearchModalContext();
  const { altCoords } = useMapContext();

  return (
    <>
      <div className={style.search_area}>
        <div className={style.search_container}>
          <div className={style.with_routes}>
            <div className={style.search_content}>
              <div className={style.hamburger_container}>
                <button
                  className={style.hamburger}
                  type="button"
                  onClick={() => setOpen(!open)}
                >
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                </button>
              </div>
              <div className={style.search_field}>
                <div className={style.search_origin}>
                  <SearchButton
                    placeholder={origin !== null ? origin.address : "Origin"}
                    icon={<MdOutlineTripOrigin />}
                    onClick={() => onOpen("origin")}
                  />
                </div>
                <div className={style.search_destination}>
                  <SearchButton
                    placeholder={
                      destination !== null ? destination.address : "Destination"
                    }
                    icon={<FaLocationDot />}
                    onClick={() => onOpen("destination")}
                  />
                </div>
              </div>
            </div>
            {/* <div className={style.suggestion}>
              <div className={style.dropdown_container}>
                <div className={style.search_dropdown}>
                  <ul className={style.dropdown_list} role="listbox">
                    <li
                      className={`${style.search_item} ${style.is_current_location}`}
                      tabIndex="-1"
                      role="option"
                      aria-selected="false"
                    >
                      <span className={style.search_item_primary}>
                        Your location
                      </span>
                    </li>
                    <li className={style.search_item}>
                      <span className={style.search_item_text}>
                        <span className={style.search_item_primary}>
                          Farm City
                        </span>
                        <span className={style.search_item_secondary}>
                          Lagos
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className={style.scrollable}>
              <div className={style.sticky_line}></div>
              {altCoords?.length > 0 && <RouteList />}
            </div>
          </div>
        </div>
      </div>
      {altCoords?.length > 0 && <RouteMobile />}
      {isOpen && <SearchModal />}
    </>
  );
};

export { SearchArea };
