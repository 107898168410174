import { createContext, useContext, useState } from "react";

const MapContext = createContext();

export const useMapContext = () => useContext(MapContext);

const MapProvider = ({ children }) => {
  const [coords, setCoords] = useState({});
  const [altCoords, setAltCoords] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null);

  return (
    <MapContext.Provider
      value={{
        coords,
        altCoords,
        selectedRoute,
        setCoords,
        setAltCoords,
        setSelectedRoute
      }}
    >
      <div id="map" className="map">
        {children}
      </div>
    </MapContext.Provider>
  );
};

export { MapProvider };
