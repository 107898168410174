import { useMapContext } from "../../lib/contexts/MapContext";
import useDeviceDetection from "../../lib/hooks/useDevice";
import { Each } from "../Utilities/Each/Each";
import style from "./RouteMobile.module.css";

const mobile = {
  top: "50%"
};

const desktop = {
  bottom: "0px"
};
const RouteMobile = () => {
  const { altCoords, setSelectedRoute } = useMapContext();
  const device = useDeviceDetection();
  return (
    <div className={style.routes_mobile} style={device ? mobile : desktop }>
      <ul className={style.route_content}>
        <Each
          of={altCoords}
          render={(coord, index) => (
            <li className={style.route_list_item} key={index}>
              <button
                className={style.route_info}
                onClick={() => {
                  setSelectedRoute(coord);
                }}
              >
                <span>Route {index + 1}</span>
                <span>Distance: {coord.distance} km</span>
                <span>Duration: {coord.duration}</span>
                <span>Description: {coord.description}</span>
              </button>
            </li>
          )}
        />
      </ul>
    </div>
  );
};

export { RouteMobile };
