import style from "./RouteItem.module.css";

const RouteItem = ({
  coord,
  currentRouteIndex,
  index,
  setCurrentRouteIndex,
  setSelectedRoute,
}) => {
  return (
    <li
      className={`${currentRouteIndex === index && style.current_route} ${style.route_list_item}`}
    >
      <button
        className={style.route_info}
        onClick={() => {
          setSelectedRoute(coord);
          setCurrentRouteIndex(index);
        }}
      >
        <div className={style.header}>
          <span className={style.route_index}>{index + 1}</span>
          <span>
            <span title={coord.duration}>{coord.duration}</span>
          </span>
        </div>
        <div className={style.details}>
          <span title={coord.distance}>
            <span dir="auto">{coord.description}</span>
            <div className={style.footer}>{coord.distance} km</div>
          </span>
        </div>
      </button>
    </li>
  );
};

export { RouteItem };
