const convertTime = (seconds) => {
  let days     = Math.floor(seconds / (24*60*60));
    seconds -= days    * (24*60*60);
  let hours    = Math.floor(seconds / (60*60));
    seconds -= hours   * (60*60);
  let minutes  = Math.floor(seconds / (60));
    seconds -= minutes * (60);
  return ((0<days)?(days+" day, "):"")+hours+"h "+minutes+"m";
};

export { convertTime };
