
export const InfoWindowContent = (marker) => {
  return `<div class="info_card">
    <p class="address">${marker.address}</p>
    <div class="coordinates">
      <p>Lat: <span>${marker.lat}</span></p>
      <p>Lng: <span>${marker.lng}</span></p>
    </div>
   </div>`
};
