const FIELDMASK = "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline,routes.description";
const URL = "https://routes.googleapis.com/directions/v2:computeRoutes"

const getRoute = async (coord) => {
  const response = await fetch(URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_MAP_API_KEY,
      "X-Goog-FieldMask": FIELDMASK,
    },
    body: JSON.stringify({
      origin: coord.origin,
      destination: coord.destination,
      travelMode: "DRIVE",
      routingPreference: "TRAFFIC_AWARE",
      computeAlternativeRoutes: true,
      polylineQuality: "HIGH_QUALITY",
      polylineEncoding: "ENCODED_POLYLINE",
    }),
  });
  return response.json();
};

const computeRoute = async (origin, destination) => {
  return await getRoute({
    origin: {
      location: {
        latLng: {
          ...origin
        }
      }
    },
    destination: {
      location: {
        latLng: {
          ...destination
        }
      }
    },
  });
};
  
export { computeRoute };
  