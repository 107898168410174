import { useState } from "react";
import { useMapContext } from "../../lib/contexts/MapContext";
import { RouteItem } from "../RouteItem/RouteItem";
import { Each } from "../Utilities/Each/Each";
import style from "./RouteList.module.css";

const RouteList = () => {
  const { altCoords, setSelectedRoute } = useMapContext();
  const [curentRouteIndex, setCurrentRouteIndex] = useState(0);
  return (
    <>
      <div className={`${style.routes_desktop}`}>
        <div className={style.routes_header}>
          <span className={style.title}>Routes</span>
        </div>
        <ul className={style.route_list}>
          <Each
            of={altCoords}
            render={(coord, index) => (
              <RouteItem
                coord={coord}
                currentRouteIndex={curentRouteIndex}
                setCurrentRouteIndex={setCurrentRouteIndex}
                setSelectedRoute={setSelectedRoute}
                index={index}
                key={index}
              />
            )}
          />
        </ul>
      </div>
    </>
  );
};

export { RouteList };
