import { useEffect, useState } from "react";

const useLocation = () => {
  const [location, setLocation] = useState(null);
  useEffect(() => {
    let watchId = null;
    if ('geolocation' in navigator) {
      watchId = navigator.geolocation.watchPosition((position) => {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        })
      }, (error) => {
        console.log(error)
      });
    }

    return () => {
      if(watchId) {
        navigator.geolocation.clearWatch(watchId)
      }
    }
  }, []);
  return [ location ]
};

export { useLocation };
